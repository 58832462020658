import * as React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'
import "@fontsource/raleway/300.css"
import "@fontsource/raleway/500.css"
import styled from 'styled-components'
import { Helmet } from "react-helmet"
import { StaticImage } from 'gatsby-plugin-image'
import { HighlightText } from '../components/generalstyling'
import Hero from '../components/hero'

// styles
const MainBodySection = styled.article`
  max-width: 960px;
  margin: 0 auto 20px;

  > h4 {
    max-width: 920px;
    margin: 30px auto 0px auto;

    @media only screen and (max-width: 920px) {
      padding: 0 20px;
    }
  }
  }
`;

// markup
const AboutPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/about" />
          <meta name="description" content="The people behind The Opera 101" />
        </Helmet>
        <Hero title="About Us"/>
        <MainBodySection>

          <HighlightText>The Opera 101 was first created in 2013 by <a href="https://robertwalport.com">Robert Walport</a> to try and fill the void between where simple online synopses ended and in-depth books began. Initially, we covered just three operas but have grown to cover sixteen, with extensive information on various opera topics and a huge searchable list of opera running times.</HighlightText>

          <HighlightText>If you’re interested in those behind the site, Robert has a tech start-up background but is heavily involved in the arts, most recently writing <a href="https://investinbroadway.com">a guide about his and his partner’s experience of commercial theatre investing</a>. He can be found in theatres multiple times weekly and is fortunate to have attended over 500 opera performances. Various others have contributed to the site over the years, but most significantly, Cory Oldweiler. He’s a book critic and author. Check out his first novel: <a href="https://amzn.to/3ZxOiif">Testimony of the Senses</a>.</HighlightText> 

        </MainBodySection>
    </Layout>
  )
}

export default AboutPage
